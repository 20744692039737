import { toPlainText } from '@portabletext/react';
import { urlForAsset } from 'src/lib/client/imageUrls';
import { StreamingContentType } from 'src/lib/movieinfo/movieinfotypes';
import { IFilmomtale } from 'src/lib/types/Filmomtale';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { parse, addMonths, isFuture, addDays } from "date-fns";
import locale from "date-fns/locale/nb";

//#region [Props]
type FilmSeoProps = {
    document?: IFilmomtale | null
    film?: StreamingContentType | null;
    isStreamingContext: boolean;
    numNationalShows: number;
};
//#endregion

//#region [Component]
export default function FilmSeo({ document, film, isStreamingContext, numNationalShows }: FilmSeoProps) {
    const router = useRouter();

    if (!document && !film) return null;

    const title = isStreamingContext ? film?.title : document?.title;
    const year: string | number | undefined = isStreamingContext ? film?.year : document?.year;

    const metaTitle = isStreamingContext ? `${title} - strømme online - Streamingguide` : `${title}${year ? ` - ${year}` : ""} - Filmweb`;

    let metaDescription = !isStreamingContext ? document?.oneliner?.trim() : film?.synopsisIngress?.trim();
    if (!isStreamingContext && !metaDescription && document?.ingress) {
        metaDescription = toPlainText(document.ingress);
    }

    const url = router.asPath.split("?", 1)[0];

    let imageUrl: string | null = null;
    if (!isStreamingContext && document?.mainImageV2) {
        imageUrl = urlForAsset(document!.mainImageV2.asset?._id ?? document!.mainImageV2.asset?._ref).auto("format").width(1080).url();
    } else if (isStreamingContext && (film?.imagesWide?.length ?? 0) > 0) {
        imageUrl = film!.imagesWide!.sort((a, b) => (a!.width < b!.width ? 1 : -1))[0]!.url;
    }

    const canonical = findCanonical(document, film, numNationalShows);

    return <Head>
        <meta key="ogTitle" property="og:title" content={metaTitle} />

        {metaDescription && <>
            <meta key="ogDescrtiption" property="og:description" content={metaDescription} />
            <meta key="metaDescription" name="description" content={metaDescription} />
        </>}

        {canonical && <link key="canonical" rel="canonical" href={canonical} />}

        <meta key="ogUrl" property="og:url" content={`https://www.filmweb.no${url}`} />

        {imageUrl && <meta key="ogImage" property='og:image' content={imageUrl} />}

        <meta key="ogType" property="og:type" content="article" />
        <title key="title">{metaTitle}</title>

    </Head>;
}
//#endregion

function findCanonical(cmsFilmomtale: IFilmomtale | null | undefined, strFilmomtale: StreamingContentType | null | undefined, numNationalShows: number): string | null {
    /**
    From SAN-549:
    Hvis filmen har premiere framover i tid: streaming → kino

    Hvis filmen hadde premiere for < 60 dager siden og flere enn 3 nasjonale forestillinger: streaming → kino

    Hvis filmen hadde premiere for < 60 dager siden og færre enn 3 nasjonale forestillinger: kino -> streaming

    Hvis filmen hadde premiere for > 300 dager siden: kino → streaming
    (uansett om den vises på kino)
     */
    let canonical: string | null = null;
    if (strFilmomtale) {
        // default til streaming dersom vi har str omtale
        canonical = `https://www.filmweb.no/streamingguide/film/${strFilmomtale.id}`;
    } else if(cmsFilmomtale?.mainVersionEDI) {
        // default til kino dersom vi ikke har streaming omtale, men har edi
        canonical = `https://www.filmweb.no/film/${cmsFilmomtale.mainVersionEDI}`;
    }

    if (cmsFilmomtale?.mainVersionEDI) {
        // kun hvis vi har edi, skal vi sjekke om kinomtalen er aktuell
        if (cmsFilmomtale.premiere && cmsFilmomtale.premiere.noCinemaRelease !== true && (cmsFilmomtale.premiere.premiereDate || cmsFilmomtale.premiere.expectedPremiereDate)) {
            const premDate = parse((cmsFilmomtale.premiere.premiereDate ?? cmsFilmomtale.premiere.expectedPremiereDate)!, "yyyy-MM-dd", new Date(), { locale });
            if (isFuture(premDate)) {
                // Hvis filmen har premiere framover i tid: streaming → kino
                canonical = `https://www.filmweb.no/film/${cmsFilmomtale.mainVersionEDI}`;
            } else if (isFuture(addDays(premDate, 60))) {
                // Hvis filmen hadde premiere for < 60 dager siden...
                if( numNationalShows > 3) {
                    // ... og flere enn 3 nasjonale forestillinger: streaming → kino
                    canonical = `https://www.filmweb.no/film/${cmsFilmomtale.mainVersionEDI}`;
                }
            }
        }
    }
    return canonical;
}